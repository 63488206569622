<template>
  <ContentWrapper :links="topLinks">
    <Form inline>
      <FormItem>
        <ButtonGroup>
          <Button
            type="primary"
            @click="
              dialogImmediate = false
              dialogDisplay = true
              dialogTitle = '新建'
            "
          >
            计划推送
          </Button>
          <Button
            type="error"
            @click="
              dialogImmediate = true
              dialogDisplay = true
              dialogTitle = '新建'
            "
          >
            立即推送
          </Button>
        </ButtonGroup>
      </FormItem>
      <FormItem>
        <Input v-model="queryForm.title" type="text" placeholder="关键词" />
      </FormItem>
      <!-- <FormItem>
        <FormItem>
          <Select v-model="queryForm.status" placeholder="状态">
            <Option :value="-1">不限</Option>
            <Option :value="1">计划中</Option>
            <Option :value="2">已推送</Option>
            <Option :value="3">已取消</Option>
          </Select>
        </FormItem>
      </FormItem> -->
      <FormItem>
        <DatePicker
          v-model="scheduleDateRange"
          type="datetimerange"
          placement="bottom-end"
          placeholder="计划推送时间范围"
          style="width: 200px"
        ></DatePicker>
      </FormItem>
      <!-- <FormItem>
        <DatePicker
          v-model="sentDateRange"
          type="datetimerange"
          placement="bottom-end"
          placeholder="已推送时间范围"
          style="width: 200px"
        ></DatePicker>
      </FormItem> -->
      <FormItem>
        <DatePicker
          v-model="dateRange"
          type="datetimerange"
          placement="bottom-end"
          placeholder="创建时间范围"
          style="width: 200px"
        ></DatePicker>
      </FormItem>
    </Form>
    <Table :data="itemList" :columns="tableColumns" style="width: 100%" />
    <Pager :total="itemListCount" :current.sync="page" />
    <Modal v-model="dialogDisplay" :title="dialogTitle" @on-ok="onSubmit">
      <NotificationEditor ref="editor" :payload="currentNotification" :immediate="dialogImmediate" />
    </Modal>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import Pager from "@/components/Pager.vue"
import NotificationEditor from "@/components/operation/NotificationEditor.vue"
import { queryNotification, deleteNotification, updateNotification, createNotification } from "@/api"
import { apiUtil, convertDateFromArray, watchReload, editAndRemove } from "@/util"
import "@/components/tableUtil"

export default {
  name: "PlanQueryPage",
  components: { ContentWrapper, Pager, NotificationEditor },
  data() {
    return {
      topLinks: [{ title: "推送消息", link: this.$route.path }],
      dateRange: ["", ""],
      scheduleDateRange: ["", ""],
      sentDateRange: ["", ""],
      page: 1,
      queryForm: {
        title: "",
        startScheduleTime: null,
        endScheduleTime: null,
        startSentTime: null,
        endSentTime: null,
        startTime: null,
        endTime: null,
      },
      itemList: [],
      itemListCount: 0,
      dialogDisplay: false,
      dialogImmediate: false,
      dialogTitle: "",
      currentNotification: {},
      tableColumns: [
        { title: "标识", key: "id" },
        { title: "标题", key: "title" },
        { title: "内容", key: "message" },
        {
          title: "计划时间",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.scheduledAt, defText: "立即" },
            }),
        },
        {
          title: "推送时间",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.sentAt, defText: "未推送" },
            }),
        },
        {
          title: "操作",
          width: 200,
          render: (h, params) =>
            editAndRemove.call(
              this,
              h,
              params,
              () => {
                this.editNotification(params.row)
              },
              async () => {
                await this.deleteNotification(params.row.id)
              },
              "将会删除推送计划, 但对于已发送的推送并不会因为删除撤回, 继续?",
              [
                h(
                  "Button",
                  {
                    props: { type: "primary" },
                    on: {
                      click: () => {
                        this.editNotification(params.row)
                        this.currentNotification.id = null
                        this.dialogTitle = "新建"
                      },
                    },
                  },
                  "以此新建"
                ),
              ]
            ),
        },
      ],
    }
  },
  computed: {},
  watch: {
    ...watchReload(["queryForm", "scheduleDateRange", "sentDateRange", "dateRange", "page"]),
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      apiUtil.call(this, async () => {
        const queryForm = Object.assign({}, this.queryForm)
        let dr = {}
        dr = convertDateFromArray(this.dateRange)
        queryForm.startTime = dr.start
        queryForm.endTime = dr.end
        dr = convertDateFromArray(this.scheduleDateRange)
        queryForm.startScheduleTime = dr.start
        queryForm.endScheduleTime = dr.end
        dr = convertDateFromArray(this.sentDateRange)
        queryForm.startSentTime = dr.start
        queryForm.endSentTime = dr.end
        const r = await queryNotification(
          this.page,
          queryForm.title,
          queryForm.startScheduleTime,
          queryForm.endScheduleTime,
          queryForm.startSentTime,
          queryForm.endSentTime,
          queryForm.startTime,
          queryForm.endTime
        )
        this.itemList = r.items
        this.itemListCount = r.count
      })
    },
    editNotification(row) {
      const t = {}
      t.id = row.id
      t.title = row.title
      t.message = row.message
      t.scheduledAt = row.scheduledAt ? new Date(row.scheduledAt) : null
      this.currentNotification = t
      this.dialogDisplay = true
      this.dialogTitle = "编辑"
    },
    deleteNotification(id) {
      apiUtil.call(this, async () => {
        await deleteNotification(id)
        this.$Message.success("删除成功")
        await this.reload()
      })
    },
    onSubmit() {
      apiUtil.call(this, async () => {
        const editor = this.$refs.editor
        const formData = Object.assign({}, editor.formData)
        formData.scheduledAt = formData.scheduledAt ? formData.scheduledAt.getTime() : null
        if (formData.id) {
          await updateNotification(formData)
          this.$Message.success("编辑成功")
        } else {
          await createNotification(formData)
          this.$Message.success("创建成功")
        }
        await this.reload()
      })
    },
  },
}
</script>
