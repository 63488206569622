<template>
  <Form :labelWidth="60">
    <FormItem label="类型">
      <Select v-model="formData.planType">
        <Option v-for="(planT, typeName) in availableTypes" :key="typeName" :value="typeName">
          {{ planT.name }}
        </Option>
      </Select>
    </FormItem>
    <FormItem label="标题">
      <Input v-model="formData.title" placeholder="输入推送标题" />
    </FormItem>
    <FormItem :label="currentPlanValueContentLabel" v-if="formData.planType != 'TEXT'">
      <Input
        v-model="formData.planValue"
        placeholder=""
        :disabled="PLAN_TYPES[formData.planType].planValueContentType != 'TEXT'"
      />
      <Button v-if="PLAN_TYPES[formData.planType].planValueContentType == 'ITEM'" @click="btnItemSelect">
        选择商品
      </Button>
      <Button v-if="PLAN_TYPES[formData.planType].planValueContentType == 'CATEGORY'" @click="btnCategorySelect">
        选择分类
      </Button>
      <Button
        v-if="PLAN_TYPES[formData.planType].planValueContentType == 'USER'"
        @click="btnUserSelect"
      >
        选择卖家
      </Button>
    </FormItem>
    <FormItem label="图片" v-if="PLAN_TYPES[formData.planType].planValueContentType == 'IMAGE'">
      <ImageUploader
        :defaultImages="currentImages"
        :defaultCrop="formData.relatedMediaSuffix"
        enableCrop
        :width="250"
        :height="250"
        @uploadSucceed="imageUploaded"
        @removedUploaded="imageRemoved"
      />
    </FormItem>
    <FormItem label="内容">
      <Input v-model="formData.message" type="textarea" placeholder="推送正文" />
    </FormItem>
    <FormItem label="时间" v-if="!immediate">
      <DatePicker v-model="formData.scheduledAt" type="datetime" placeholder="计划推送时间" />
    </FormItem>
    <Modal v-model="showItemDialog" fullscreen>
      <ItemSelector v-if="showItemDialog" @itemSelected="itemSelected" />
      <div slot="footer"></div>
    </Modal>
    <Modal v-model="showCategoryDialog" width="400px">
      <CategorySelector v-if="showCategoryDialog" @categorySelected="categorySelected" />
      <div slot="footer"></div>
    </Modal>
    <Modal v-model="showUserSelectorDialog" width="80%">
      <UserSelector v-if="showUserSelectorDialog" :max="1" @usersChanged="userSelected" />
      <div slot="footer">
        <Button @click="showUserSelectorDialog = false">确定</Button>
      </div>
    </Modal>
  </Form>
</template>

<script>
import ItemSelector from "@/components/item/ItemSelector"
import CategorySelector from "@/components/cate/CategorySelector"
import UserSelector from "@/components/user/UserSelector"
import ImageUploader from "@/components/ImageUploader"
const { PLAN_TYPES, NOTIFICATION_TYPES } = require("@/constants")
const initData = () => {
  return {
    id: null,
    title: "",
    message: "",
    scheduledAt: null,
    relatedMediaId: null,
    relatedMediaPath: null,
    relatedMediaSuffix: "",
    relatedMediaUrl: null,
    planType: "TEXT",
  }
}

export default {
  name: "NotificationEditor",
  components: { ImageUploader, ItemSelector, CategorySelector, UserSelector },
  props: {
    payload: {
      type: Object,
      default: () => initData(),
    },
    immediate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      PLAN_TYPES,
      formData: Object.assign({}, initData(), this.payload),
      showItemDialog: false,
      showCategoryDialog: false,
      showUserSelectorDialog: false,
    }
  },
  computed: {
    availableTypes() {
      const types = NOTIFICATION_TYPES.reduce((types) => {
        NOTIFICATION_TYPES.forEach((key) => (types[key] = PLAN_TYPES[key]))
        return types
      }, {})
      return types
    },
    currentImages() {
      if (this.formData.relatedMediaPath && this.formData.relatedMediaPath != "") {
        return [this.formData.relatedMediaPath]
      }
      return []
    },
    currentPlanValueContentLabel() {
      if (this.formData.planType != null) return PLAN_TYPES[this.formData.planType].planValueContentLabel
      else return "请选择类型"
    },
  },
  watch: {
    payload(newP) {
      this.formData = Object.assign({}, initData(), newP)
    },
    "formData.planType"() {
      this.formData.planValue = ""
    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {},
    doReset() {},
    async doUpdate() {},
    imageUploaded(uploadedImages) {
      if (uploadedImages && uploadedImages.length > 0) {
        // this.formData.relatedMediaPath = uploadedImages[0].path
        // this.formData.relatedMediaSuffix = uploadedImages[0].suffix
        this.$set(this.formData, "relatedMediaPath", uploadedImages[0].path)
        this.$set(this.formData, "relatedMediaSuffix", uploadedImages[0].suffix)
        this.$set(this.formData, "planValue", uploadedImages[0].url)
      } else {
        this.formData.relatedMediaId = null
        this.formData.relatedMediaPath = ""
        this.formData.relatedMediaSuffix = ""
      }
    },
    imageRemoved() {
      this.formData.relatedMediaId = null
      this.formData.relatedMediaPath = ""
      this.formData.relatedMediaSuffix = ""
    },
    btnItemSelect() {
      this.showItemDialog = true
    },
    itemSelected(item) {
      this.showItemDialog = false
      this.formData.planValue = item.id
    },
    btnCategorySelect() {
      this.showCategoryDialog = true
    },
    categorySelected(category) {
      this.showCategoryDialog = false
      this.formData.planValue = category.id
    },
    btnUserSelect() {
      this.showUserSelectorDialog = true
    },
    userSelected(users) {
      // this.showUserSelectorDialog = false
      if (users && users.length > 0) {
        this.formData.planValue = users[0].id
      }
    },
  },
}
</script>

<style></style>
