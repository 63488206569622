<template>
  <div>
    <Form inline>
      <FormItem>
        <Input v-model="keyword" type="text" placeholder="搜索" />
      </FormItem>
      <!-- <FormItem>
        <Select v-model="recommendType" v-if="variant">
          <Option value="">
            全部
          </Option>
          <Option value="NORMAL">
            普通商品
          </Option>
          <Option value="SECKILL">
            秒杀商品
          </Option>
        </Select>
      </FormItem> -->
    </Form>
    <Table stripe :columns="columns" :data="itemList" :loading="tableLoading"></Table>
    <Pager :total="itemListCount" :current.sync="page" />
  </div>
</template>

<script>
import Pager from "@/components/Pager.vue"

import { queryItem, queryItemIds } from "@/api"
import { toHumanDate, centiToOne, imgHolder } from "@/util"

export default {
  name: "ItemSelector",
  components: { Pager },
  props: {
    itemAlias: { type: String, default: "" },
    variant: { type: String, default: "" },
    initRecommendType: { type: String, default: "" }
  },
  data() {
    return {
      ids: {},
      itemList: [],
      itemListCount: 0,
      keyword: null,
      recommendType: "",
      tableLoading: false,
      columns: [
        { title: "ID", key: "id", width: 80, maxWidth: 80, fixed: "left" },
        { title: "标识", key: "itemAlias", width: 100, maxWidth: 200, fixed: "left" },
        {
          title: "主图",
          width: 100,
          render: (h, params) => {
            return imgHolder(h, params.row.image?.url, () => {
              this.$emit("itemSelected", params.row)
            })
          },
          fixed: "left",
        },
        {
          title: "标题",
          maxWidth: 200,
          minWidth: 100,
          render: (h, params) => {
            return h(
              "a",
              {
                on: {
                  click: () => {
                    this.$emit("itemSelected", params.row)
                  },
                },
              },
              [params.row.title],
            )
          },
          fixed: "left",
        },
        {
          title: "卖家",
          render(h, p) {
            return h("div", p.row.seller.nickname)
          },
          width: 100,
          fixed: "left",
        },
        {
          title: "价格",
          render(h, params) {
            return h("div", centiToOne(params.row.price))
          },
        },
        {
          title: "创建时间",
          key: "createdAt",
          render: (h, p) => h("div", toHumanDate(p.row.createdAt)),
        },
        {
          title: "上架时间",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.listedAt, defText: "-" },
            }),
        },
      ],
      page: 1,
    }
  },
  async mounted() {
    this.recommendType = this.initRecommendType
    await this.reload()
  },
  watch: {
    page() {
      this.reload()
    },
    keyword() {
      this.reload()
    },
    recommendType() {
      if (this.page === 1) {
        this.reload()
      } else {
        this.page = 1
      }
    },
  },
  methods: {
    async reload() {
      try {
        this.tableLoading = true
        const {
          items,
          count,
        } = await queryItem(null, this.page, this.keyword, null, null, null, null, this.variant, this.recommendType === "" ? null : this.recommendType)
        const idMap = await queryItemIds(items.map(i => i.itemAlias))
        items.forEach(item => {
          item.id = idMap[item.itemAlias]
        })
        this.itemList = items
        this.itemListCount = count
        this.tableLoading = false
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
  },
}
</script>
